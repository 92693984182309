/**
 * @license
 * Copyright 2018 Google LLC. All Rights Reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * =============================================================================
 */

import {linearRegressionModel, multiLayerPerceptronRegressionModel1Hidden, multiLayerPerceptronRegressionModel2Hidden, run} from '.';

import renderChart from 'vega-embed';

const statusElement = document.getElementById('status');
export function updateStatus(message) {
  statusElement.innerText = message;
};

const baselineStatusElement = document.getElementById('baselineStatus');
export function updateBaselineStatus(message) {
  baselineStatusElement.innerText = message;
};

export function updateModelStatus(message, modelName) {
  const statElement = document.querySelector(`#${modelName} .status`);
  statElement.innerText = message;
};

const NUM_TOP_WEIGHTS_TO_DISPLAY = 5;
/**
 * Updates the weights output area to include information about the weights
 * learned in a simple linear model.
 * @param {List} weightsList list of objects with 'value':number and
 *     'description':string
 */
export function updateWeightDescription(weightsList) {
  const inspectionHeadlineElement =
      document.getElementById('inspectionHeadline');
  inspectionHeadlineElement.innerText =
      `物件価格に影響を与えている要因トップ ${NUM_TOP_WEIGHTS_TO_DISPLAY}：`;
  // Sort weights objects by descending absolute value.
  weightsList.sort((a, b) => Math.abs(b.value) - Math.abs(a.value));
  var table = document.getElementById('myTable');
  // Clear out table contents
  table.innerHTML = '';
  // Add new rows to table.
  weightsList.forEach((weight, i) => {
    if (i < NUM_TOP_WEIGHTS_TO_DISPLAY) {
      let row = table.insertRow(-1);
      let cell1 = row.insertCell(0);
      let cell2 = row.insertCell(1);
      if (weight.value < 0) {
        cell2.setAttribute('class', 'negativeWeight');
      } else {
        cell2.setAttribute('class', 'positiveWeight');
      }
      cell1.innerHTML = weight.description;
      cell2.innerHTML = weight.value.toFixed(4);
    }
  });
};

export async function setup() {
  const trainSimpleLinearRegression = document.getElementById('simple-mlr');
  const trainNeuralNetworkLinearRegression1Hidden =
      document.getElementById('nn-mlr-1hidden');
  const trainNeuralNetworkLinearRegression2Hidden =
      document.getElementById('nn-mlr-2hidden');

  trainSimpleLinearRegression.addEventListener('click', async (e) => {
    const model = linearRegressionModel();
    await run(model, 'linear', true);
  }, false);

  trainNeuralNetworkLinearRegression1Hidden.addEventListener(
      'click', async () => {
        const model = multiLayerPerceptronRegressionModel1Hidden();
        await run(model, 'oneHidden', false);
      }, false);

  trainNeuralNetworkLinearRegression2Hidden.addEventListener(
      'click', async () => {
        const model = multiLayerPerceptronRegressionModel2Hidden();
        await run(model, 'twoHidden', false);
      }, false);
};


export function clearEvaluateTable() {
  const tableBody = document.getElementById('evaluate-tbody');
  console.log("clear " + tableBody.children.length)
  while (tableBody.children.length > 0) {
    tableBody.removeChild(tableBody.children[0]);
  }
};

export function renderEvaluateTable(xData, yTrue, yPred) {
  const tableBody = document.getElementById('evaluate-tbody');
  var saleType;
  
  for (let i = 0; i < yTrue.length; ++i) {
    const row = document.createElement('tr');
    for (let j = 0; j < 5; ++j) {
	  
	  const cell = document.createElement('td');
	  
	  //sale type
	  if(j == 0){
		  saleType = xData[5 * i + j].toFixed(0);
		  if(saleType == 3)
		  { cell.textContent = "中古一戸建て"; }
		  else if(saleType == 4)
		  { cell.textContent = "新築一戸建て"; }
			  
		   
	  }
	  else{
	     cell.textContent = xData[5 * i + j].toFixed(0);
	  }
      row.appendChild(cell);
    }
	
    const truthCell = document.createElement('td');
    truthCell.textContent = yTrue[i];
    row.appendChild(truthCell);
	
    const predCell = document.createElement('td');
    predCell.textContent = yPred[i];
    row.appendChild(predCell);

    tableBody.appendChild(row);
  }
};



 export async function plotData(container, xs, ys) {
   const xvals = xs;
   const yvals = ys;

   const values = Object.keys(yvals).map((i) => {
	  
     return {'実際の価格': xvals[i][0], '予測した価格': yvals[i], '理想値（赤線）': xvals[i][0]};
   });
   

   const spec = {
     '$schema': 'https://vega.github.io/schema/vega-lite/v2.json',
     'width': 300,
     'height': 300,
     'data': {'values': values},

     'layer': [
	 　　//予測した価格と予測価格の分布
       {
         'mark': 'point',
         'encoding': {
       'x': {'field': '実際の価格', 'type': 'quantitative', "scale": {"domain": [0, 10]}},
       'y': {'field': '予測した価格', 'type': 'quantitative', "scale": {"domain": [0, 10]}}
         }
       },
	   
	   //理想の予測線
       {
         'mark': 'line',
         'encoding': {
           'x': {'field': '理想値（赤線）', 'type': 'quantitative'},
           'y': {'field': '理想値（赤線）', 'type': 'quantitative'},
           'color': {'value': 'tomato'}
         }
       }
     ]

   };

   return renderChart(container, spec, {actions: false});
 };

